import React from "react";
import {
  View,
  SafeAreaView,
  StatusBar,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from "react-native";
import { Text } from "react-native-paper";
import NavigationHeader from "../../Common/NavigationHeader";
import { SideHeading } from "../../Common/sideHeading";
import CustButton from "../../Common/Button";
import { ImportedPortfolioStatus } from "../../Common/ImportedPortfolioStatus";
import Icon from "@expo/vector-icons/Octicons";
import MIcon from "@expo/vector-icons/MaterialIcons";
import { uploadStockMF } from "../../../api";
import {
  DataURIToBlob,
  completeMinDetails,
  getUserDetails,
  isMobile,
  selectOneFile,
} from "../../../utils";
import { StackActions } from "@react-navigation/native";
import Store from "../../../store";

export default class ImportStocksDetails extends React.Component {
  constructor(props) {
    super(props);
    let file = null;
    let isOnBoarding = "false"
    const params = this.props.route;
    if (params) {
      file = params.params.file || null;
      isOnBoarding = params.params.isOnBoarding || "false"
    }
    this.state = {
      data: [],
      isOTPValid: null,
      success: null,
      isOnBoarding,
      file,
      error: null,
    };
  }

  static navigationOptions = () => ({
    tabBarVisible: false,
  });

  _uploadFile = async () => {
    this.setState({ isLoading: true });
    const { file } = this.state;
    if (!file) return;
    const { assets } = file;
    const name = assets[0]["name"];
    const uri = assets[0]["uri"];
    const { username } = getUserDetails();
    let body = new FormData();
    const imageFile = DataURIToBlob(uri);
    body.append("file_type", "stock_imported");
    body.append("uploaded_excelname", name);
    body.append("uploaded_excel", imageFile, name);
    body.append("advisor", username);
    body.append("contract_note", null);
    body.append("password", "");
    body.append("subject", "");
    if (
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      body.append("cust_pk", Store.getState().dashboard.viewModeUserId.user_id);
    }
    uploadStockMF(body)
      .then((resp) => {
        if (resp.code == 100) {
          this.setState({ success: true });
        } else {
          this.setState({
            success: false,
            error: resp.result && resp.result[0] && resp.result[0].Remarks,
          });
          this._generateErrorExcel(resp.result);
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });

  };

  _generateErrorExcel = (errorData) => {
    const XLSX = require('xlsx');
    const ws = XLSX.utils.json_to_sheet(errorData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Error Data");
    XLSX.writeFile(wb, "error_data.xlsx");
  };

  _header() {
    return (
      <NavigationHeader
        navigation={this.props.navigation}
        navigationHeader={["Import Stock Portfolio"]}
        onPressBack={() => {
          this.props.navigation.navigate("App", {
            screen: "ViewMode",
            params: {
              screen: "ImportStocks",
              params: {},
            },
          })
        }}
      />
    );
  }

  _renderItem = ({ index }) => {
    return (
      <ImportedPortfolioStatus
        file="michaelscott@dundermifflin.com"
        status={"E-mail pending"}
        type={"12/12/2019"}
        key={index}
      />
    );
  };

  _listHeader = () => {
    return (
      <View style={{ flexDirection: "row", paddingBottom: 5 }}>
        <Text
          style={{
            flex: 0.5,
            textAlign: "left",
            color: "#707070",
            fontSize: 14,
          }}
        >
          Recent Uploads
        </Text>
        <Text
          style={{
            flex: 0.45,
            color: "#707070",
            fontSize: 14,
            textAlign: "right",
          }}
        >
          status
        </Text>
      </View>
    );
  };

  _isAlreadyExist = (emails, email) => {
    return (
      emails
        .map((item) => {
          return item["value"];
        })
        .indexOf(email) != -1
    );
  };

  _selectFile = (fileType) => {
    this.setState({ fileLoading: false });
    selectOneFile(fileType).then((res) => {
      if (res) {
        this.setState({ file: res });
      }
    });
  };

  _renderResultScreen = (status) => {
    const { file } = this.state;
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          paddingTop: 120,
        }}
      >
        <Text style={{ fontSize: 20, color: "#121212" }}>
          {status ? "Stocks Uploaded Successfully" : "Oops!"}
        </Text>
        <Text style={{ fontSize: 14, color: "#121212", paddingTop: 10 }}>
          {status
            ? "Your stock portfolio is updated."
            : this.state.error
              ? this.state.error
              : "There seems to be a problem while uploading a file."}
        </Text>
        {file && file["name"] && (
          <View style={[localStyles.detailsContainer, { marginTop: 5 }]}>
            <Icon name="file" style={localStyles.fileIcon} />
            <Text style={localStyles.fileName}>{file["name"]}</Text>
            <MIcon
              name={status ? "check-circle" : "error"}
              style={{
                fontSize: 16,
                paddingLeft: 15,
                color: status ? "#009105" : "#EF5D12",
              }}
            />
          </View>
        )}
        <View
          style={{
            width: "100%",
            paddingTop: 100,
            flex: 1,
            alignItems: "center",
          }}
        >
          <CustButton
            bgColor={"#410DAA"}
            textColor={"#fff"}
            style={{
              marginVertical: 5,
              width: isMobile ? "100%" : "30%",
              marginHorizontal: isMobile ? 0 : "35%",
              marginBottom: isMobile ? 66 : 0,
              paddingVertical: 15,
            }}
            onPress={async () => {
              // console.log("The status we get is ", status);
              // if (status =="check-circle" ){
              // if (this.state.isOnBoarding == "false") {
              if (this.state.isOnBoarding == "true") {
                const minDetailsResponse = await completeMinDetails();

                if (minDetailsResponse.needCompletion) {
                  this.props.navigation.navigate("Onboarding", {
                    screen: minDetailsResponse.redirect_screen
                  });
                } else {
                  this.props.navigation.navigate("App", {
                    screen: "Dashboard"
                  });
                }
              } else {
                this.props.navigation.dispatch(
                  StackActions.replace("App", {
                    screen: "ViewMode",
                    params: {
                      screen: "ImportStocks",
                      params: {},
                    },
                  })
                );
              }
              //   } else {
              //     this.props.navigation.navigate("Onboarding",{
              //         screen: "SignUpFinancialPlan",
              //       });
              //   }
              // }else{
              //   this.props.navigation.navigate("Onboarding",{
              //     screen: "SignUpImportPortfolio",
              //   });
              // }
            }}
            borderColor={"transparent"}
            buttonTextStyle={{
              fontSize: 14,
            }}
          >
            Done
          </CustButton>
        </View>
      </View>
    );
  };

  _renderFile = () => {
    // console.log("file object is ", this.state.file);

    const { file } = this.state;

    if (!file || file.canceled) {
      return null;
    }

    const { assets } = file;

    return (
      <View style={{ paddingHorizontal: 16, marginTop: 50 }}>
        {assets.map((asset, index) => {
          const { name } = asset;

          return (
            <TouchableOpacity
              key={index}
              style={[localStyles.detailsContainer, { marginBottom: 10 }]}
              onPress={() =>
                this._selectFile([
                  "application/pdf",
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                ])
              }
            >
              <Icon name="file" style={localStyles.fileIcon} />
              <Text style={localStyles.fileName}>{name}</Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };



  render() {
    const { isLoading, success, isOnBoarding } = this.state;
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff", maxWidth: 800, marginLeft: isOnBoarding == "true" ? '25%' : 0 }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        {success != null ? (
          <View style={{}}>{this._renderResultScreen(success)}</View>
        ) : (
          <View style={{ flex: 1, paddingBottom: 100 }}>
            {this._header()}
            <SideHeading
              title="Currently selected file, click on file below to change."
              headingTitleStyle={{ fontSize: 16 }}
              style={{
                alignItems: "center",
              }}
            />
            {this._renderFile()}
            <View
              style={{ width: "100%", height: 30, backgroundColor: "#F6F6F6" }}
            ></View>
            <View
              style={{
                position: "absolute",
                bottom: 10,
                paddingHorizontal: 16,
                width: "100%",
              }}
            >
              <CustButton
                bgColor={"#410DAA"}
                textColor={"#fff"}
                style={{
                  marginVertical: 5,
                  width: isMobile ? "100%" : "30%",
                  marginHorizontal: isMobile ? 0 : "35%",
                  marginBottom: isMobile ? 66 : 0,
                  paddingVertical: 15,
                }}
                onPress={() => {
                  this._uploadFile();
                }}
                isLoading={isLoading}
                borderColor={"transparent"}
                buttonTextStyle={{
                  fontSize: 14,
                }}
              >
                Upload File
              </CustButton>
            </View>
          </View>
        )}
      </SafeAreaView>
    );
  }
}

const localStyles = StyleSheet.create({
  detailsContainer: {
    flexDirection: "row",
    paddingHorizontal: 10,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingVertical: 15,
  },
  fileIcon: { fontSize: 26, color: "#666666", alignSelf: "flex-start" },
  fileName: {
    borderBottomWidth: 1,
    borderBottomColor: "#666666",
    marginHorizontal: 10,
    fontSize: 11,
  },
  reUploadIcon: {
    fontSize: 20,
    color: "#666666",
    marginLeft: 10,
  },
  inputLabel: {
    color: "#BCBCBC",
    fontSize: 13,
    marginVertical: 0,
    paddingVertical: 0,
  },
  borderStyleBase: {
    width: 30,
    height: 45,
  },
  borderStyleHighLighted: {
    borderColor: "#410DAA",
  },
  underlineStyleBase: {
    width: 30,
    height: 45,
    borderWidth: 0,
    borderBottomWidth: 1,
    fontSize: 15,
    marginHorizontal: 0,
    paddingHorizontal: 0,
  },
  notCompleted: {
    color: "#707070",
    borderColor: "#707070",
  },
  validCode: {
    color: "#009105",
    borderColor: "#009105",
  },
  inValidCode: {
    color: "#EF5D12",
    borderColor: "#EF5D12",
  },
  underlineStyleHighLighted: {
    borderColor: "#410DAA",
  },
});
