import React, { Component } from "react";
import {
  Image,
  View,
  StyleSheet,
  SafeAreaView,
  FlatList,
  Text,
  TouchableOpacity,
  Platform,
} from "react-native";
import FIcon from "@expo/vector-icons/FontAwesome5";
import amplitude from "../../../utils/amplitude";
import EIcon from "@expo/vector-icons/Entypo";
import { ActivityIndicator, Divider } from "react-native-paper";
import Checkbox from "expo-checkbox";
import theme from "../../../static/theme";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { getBankData } from "../../../api/account";
import { getNetbankingPaymentLink } from "../../../api/invest";
import Toast from "react-native-toast-message";
import Store from "../../../store";
import {
  getUserDetails,
  isMobile,
  logIntoWebAndRedirect,
  numberWithCommas,
} from "../../../utils";
import { WebView } from "react-native-webview";

const PaymentHeader = ({ amount }) => {
  return (
    <View style={paymentHeaderStyles.container}>
      <Text style={paymentHeaderStyles.label}>Total Payable Amount</Text>
      <Text style={paymentHeaderStyles.amount}>₹ {numberWithCommas(amount)}</Text>
    </View>
  );
};

const paymentHeaderStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingHorizontal: 16,
    marginTop: 5,
    paddingTop: 15,
    paddingBottom: 15,
  },
  label: {
    flex: 0.6,
    flexWrap: "wrap",
    textAlign: "left",
    fontSize: 17,
    color: "#121212",
    fontWeight: "bold",
  },
  amount: {
    flex: 0.4,
    flexWrap: "wrap",
    textAlign: "right",
    fontSize: 17,
    color: "#121212",
    fontWeight: "bold",
  },
});

const RenderPaymentButtons = ({ onPress, is_ria, bse_url }) => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        alignItems: isMobile ? "center" : "flex-end",
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: isMobile ? "100%" : "40%",
          paddingTop: 20,
        }}
      >
        {is_ria && bse_url ? (
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              paddingTop: 15,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                global.open(bse_url, "_blank");
              }}
            >
              <Text
                style={{
                  color: "#410DAA",
                  fontSize: 16,
                  fontWeight: "600",
                  textDecorationLine: "underline",
                }}
              >
                Pay through BSE portal
              </Text>
            </TouchableOpacity>
          </View>
        ) : null}
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            paddingTop: isMobile ? 25 : 0,
            marginBottom:10
          }}
        >
          <TouchableOpacity
            style={{
              backgroundColor: "#410DAA",
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 10,
              paddingBottom: 10,
              borderWidth: 1,
              borderRadius: 10,
              borderColor: "#410DAA",
            }}
            onPress={() => onPress && onPress()}
          >
            <Text
              style={{
                color: "#fff",
                fontSize: 16,
                fontWeight: "600",
              }}
            >
              Pay now
            </Text>
            <View style={{ padding: 10 }}></View>
            <FIcon
              name="arrow-circle-right"
              size={24}
              color={"white"}
              // style={{ marginRight: 16 }}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

class ShowBankAccount extends Component {
  constructor(props) {
    super(props);
    const { params } = props.route;
    let actionId;
    let bse_url = null;
    if (params) {
      actionId = params.action || null;
      bse_url = params.url || null;
    }
    // console.log(bse_url);
    this.state = {
      currentOrders: [],
      checkedOrder: "",
      isLoading: true,
      totalSelectedAmount: 0,
      bankList: [],
      selectedBank: {},
      showWebViewBool: false,
      showHTMLContent: "",
      paymentClicked: false,
      loadingAction: false,
      actionId,
      paymentUrl: "",
      bse_url,
      is_ria: false,
    };
  }

  componentDidMount = async () => {
    if (Platform.OS == "web"){
      amplitude.logEvent("showBankAccount",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("showBankAccount", { path: this.props.navigation.getState().routeName });
    }
    let errorText = "";
    const { actions = {} } = Store.getState().transactions;
    if (actions.hasOwnProperty(this.state.actionId)) {
      const { orderdata, amount } = actions[this.state.actionId];
      this.setState({ isLoading: true });
      const { error, ...other } = await this.getUserBankAccounts();
      const { is_ria } = getUserDetails();
      if (!error) {
        this.setState({
          isLoading: false,
          orders_dict: orderdata,
          total_amount: amount,
          is_ria: is_ria,
          ...other,
        });
        return;
      } else {
        errorText = other.errorText;
      }
    } else {
      errorText = "Invalid Transaction!";
    }

    this.setState({
      isLoading: false,
      error: true,
      errorText: errorText || "Invalid Transaction!",
    });
  };

  getUserBankAccounts = async () => {
    try {
      const response = await getBankData();
      if (response) {
        const { results = [] } = response;
        if (results.length > 0) {
          let sorted_banks = results.sort(
            (a, b) =>
              b.is_default_customer_bank_account -
              a.is_default_customer_bank_account
          );
          sorted_banks = sorted_banks.filter(
            (bank) => bank.account_number != null
          );
          const default_banks = sorted_banks.filter(
            (bank) => bank.is_default_customer_bank_account
          );
          let activeBankId;
          if (default_banks.length) {
            activeBankId = default_banks[0].id;
          }
          return { bankList: sorted_banks, activeBankId, error: false };
        }
      }
      return { error: true, errorText: "Error loading bank details!" };
    } catch (error) {
      return { error: true, errorText: "Something went wrong!" };
    }
  };

  proceedToPay = async () => {
    try {
      this.setState({ isPaymentLoading: true });
      const {
        activeBankId,
        isPaymentLoading,
        orders_dict,
        total_amount,
        actionId,
      } = this.state;
      if (isPaymentLoading) return;

      if (!activeBankId) {
        Toast.show({
          text1: "Please select a bank account!",
          visibilityTime: 4 * 1000,
          topOffset: 70,
          type: "error",
        });
        this.setState({ isPaymentLoading: false });
        return;
      }

      const { account_number } = this.state.bankList.filter(
        (bank) => bank.id == activeBankId
      )[0];
      const payload = {
        // account_number: "50100274006898",
        // orders_dict: "523390611|",
        // total_amount: 100,
        account_number,
        orders_dict,
        total_amount,
        redirection_url:
          "https://" +
          (Platform.OS == "web"
            ? global.location.hostname
            : "app.jamawealth.com") +
          "/dashboard/process/" +
          actionId,
      };
      // console.log(payload)
      const response = await getNetbankingPaymentLink(payload);
      if (response && response.code == 100) {
        const { payment_link = null } = response.results;
        if (payment_link) {
          const end_point = payment_link.split("/app/");
          const redirectionEndpoint = "/app/" + end_point[1];
          const bseNetBankingLink = await logIntoWebAndRedirect(
            redirectionEndpoint
          );
          this.props.navigation.navigate("App", {
            screen: "Dashboard",
            params: {
              screen: "Portfolio",
              params: {
                screen: "ShowWebView",
                params: {
                  showInWeb: Platform.OS != "web" ? false : true,
                  loadSelf: Platform.OS != "web" ? false : true,
                  url: bseNetBankingLink,
                  successURL: "/process/",
                  onSuccess: function (navigation, results = {}) {
                    navigation.push("App", {
                      screen: "Portfolio",
                      params: {
                        screen: "Notifications",
                        params: { action_id: actionId, activeTab: 2 },
                      },
                    });
                  },
                  onError: function (navigation, results = {}) {
                    navigation.push("App", {
                      screen: "Portfolio",
                      params: {
                        screen: "Notifications",
                        params: { action_id: actionId, activeTab: 2 },
                      },
                    });
                  },
                },
              },
            },
          });
        }
      } else {
        Toast.show({
          text1: "Error generating payment link !",
          visibilityTime: 4 * 1000,
          topOffset: 145,
          type: "error",
        });
        this.setState({
          isPaymentLoading: false,
          paymentError: response.msg || "Error generating payment link",
        });
      }
    } catch (error) {
      this.setState({
        isPaymentLoading: false,
        paymentError: "Error generating payment link",
      });
    }
  };

  handleBankChange = (activeBankId) => {
    this.setState((prevState) => ({
      activeBankId: prevState.activeBankId === activeBankId ? null : activeBankId
    }));
  };

  renderBankItem = ({ item }) => {
    return (
      <TouchableOpacity
        style={{
          flexDirection: "row",
          paddingVertical: 20,
        }}
        onPress={() => {
          this.handleBankChange(item.id);
        }}
      >
        <Image
          source={{ uri: item.bank_logo }}
          resizeMode="contain"
          style={styles.image}
        />
        <View style={styles.bankDetailsContainer}>
          <Text style={styles.bankName}>{item.bank_name}</Text>
          <Text style={styles.bankAccountNumber}>
            AC/NO: XXXX
            {item.account_number.substring(
              item.account_number.length - 4,
              item.account_number.length
            )}
          </Text>
          {item.is_default_customer_bank_account && (
            <View style={styles.defaultBankAccountContainer}>
              <EIcon name="star" color="#410DAA" size={16} />
              <Text style={styles.defaultBankAccount}>
                Default Bank Account
              </Text>
            </View>
          )}
        </View>
        <View style={styles.checkBoxContainer}>
          <Checkbox
            status={
              item.id === this.state.activeBankId ? "checked" : "unchecked"
            }
            color={"#410DAA"}
            value={this.state.activeBankId}
          />
        </View>
      </TouchableOpacity>
    );
  };

  listHeader = () => {
    return <Text style={styles.debitFrom}>DEBIT FROM</Text>;
  };

  render() {
    const { bse_url, is_ria } = this.state;
    // console.log(this.state);
    return this.state.paymentUrl != "" ? (
      isMobile ? (
        <WebView source={{ uri: this.state.paymentUrl }} />
      ) : (
        global.open(this.state.paymentUrl, "_blank")
      )
    ) : (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Select Bank Account"]}
          showBackIcon={false}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        <>
          {this.state.isLoading || this.state.error ? (
            <View style={styles.loadingContainer}>
              {this.state.isLoading && (
                <ActivityIndicator size="large" color="#410DAA" />
              )}
              {this.state.error && (
                <Text style={styles.errorText}>
                  {this.state.errorText || "Something went wrong!"}
                </Text>
              )}
            </View>
          ) : (
            <>
              <PaymentHeader amount={this.state.total_amount} />
              <Divider />
              <FlatList
                data={this.state.bankList}
                renderItem={this.renderBankItem}
                ListHeaderComponent={this.listHeader}
                ItemSeparatorComponent={() => <Divider />}
                keyExtractor={(item, index) => index.toString()}
                style={{
                  paddingLeft: 0,
                  marginLeft: 0,
                  marginBottom: isMobile ? 130 : 100,
                }}
                stickyHeaderIndices={[0]}
              />
              {!this.state.error && (
                <View
                  style={{
                    position: "absolute",
                    bottom: isMobile ? 66 : 40,
                    width: "100%",
                    height: "auto",
                  }}
                >
                  <RenderPaymentButtons
                    amount={this.state.total_amount}
                    onPress={this.proceedToPay}
                    loading={this.state.isPaymentLoading}
                    is_ria={is_ria}
                    bse_url={bse_url}
                  />
                </View>
              )}
            </>
          )}
        </>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  listHeader: {
    fontSize: 12,
    color: "#121212",
    paddingBottom: 10,
    paddingTop: 10,
  },
  debitFrom: {
    fontSize: 12,
    color: "#121212",
    backgroundColor: "#fff",
    paddingVertical: 10,
    paddingHorizontal: 16,
  },
  image: {
    height: 37,
    width: 37,
    borderRadius: 18.5,
    alignSelf: "center",
    marginLeft: 30,
  },
  bankDetailsContainer: { flex: 0.8, paddingLeft: 30 },
  bankName: { fontSize: 14, color: "#121212", fontWeight: "bold" },
  bankAccountNumber: {
    fontSize: 12,
    color: "#BCBCBC",
    paddingTop: 6,
    fontWeight: "300",
  },
  defaultBankAccountContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 5,
  },
  defaultBankAccount: { fontSize: 12, color: "#cdcdcd", paddingLeft: 5 },
  checkBoxContainer: {
    flex: 0.2,
    justifyContent: "center",
    alignItems: "flex-end",
    paddingRight: 20,
  },
  errorText: {
    fontSize: 15,
    color: "#12121290",
  },
});

export default ShowBankAccount;
